import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Represents update status of an ECDN server.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Update <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ECDN software version on server. Null if server never checked in before.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">ecdnVersion</GQLName>: <GQLLink href="/ecdn-api-scalars/SemanticVersion" mdxType="GQLLink">SemanticVersion</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Current security patch level of server. Null if the server never checked in before.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">patchLevel</GQLName>: <GQLLink href="/ecdn-api-scalars/PatchLevel" mdxType="GQLLink">PatchLevel</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`ecdnVersion: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/SemanticVersion"
      }}>{`SemanticVersion`}</a></h3>
    <p>{`ECDN software version on server. Null if server never checked in before.`}</p>
    <h3>{`patchLevel: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/PatchLevel"
      }}>{`PatchLevel`}</a></h3>
    <p>{`Current security patch level of server. Null if the server never checked in before.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      